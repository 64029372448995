// export const CasterServiceBaseUrl = 'https://ziyobrpjcf.execute-api.us-west-2.amazonaws.com/Dev'; 
// export const CasterServiceWSBaseUrl = 'wss://d9iqprl9h0.execute-api.us-west-2.amazonaws.com/dev'; 
export const CasterServiceBaseUrl = 'https://lroxhe6gha.execute-api.us-west-2.amazonaws.com/dev'; 
export const CasterServiceWSBaseUrl = 'wss://64sidc9mcb.execute-api.us-west-2.amazonaws.com/dev';
export const CasterSharedServiceBaseUrl = ''; 
export const OKTA_CLIENT_ID = "0oa1g3yt7g2Bdim0I0h8";
export const OKTA_ISSUER_URL = "https://ssobizqa.wbd.com/oauth2/default";
export const OKTA_REDIRECT_URL = window.location.origin + '/callback';
export const OKTA_URL = "https://ssobizqa.wbd.com";
export const IDLE_TIMEOUT = "60";
export const IDLE_WARN_TIMEOUT = "30";